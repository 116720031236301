import React, { Component } from 'react';
import {Col } from 'react-bootstrap';

import LogoUtils from '../util/LogoUtils';
import ProjectUIUtils from '../projects/ProjectUIUtils';
import "./Chain.css";
import SPUtilities from '../util/SPUtilities';

class Chain extends Component {
    render() {
        let chain = this.props.chain;
        // console.log(chain);
        return (
            <Col className="chainCol" xs={12} sm={12} md={6} lg={6} xl={6} key={chain.app}>
                <div className="chain">
                    <div className="chainTitle">
                        <div className="chainLabel">
                            <span className="chainLogo">{Chain.chainLogo(chain.logo)}</span>
                            <span className="textLabel">{chain.name}</span>
                            <div className="chainMedia">{ProjectUIUtils.formatMediaSmall(chain.twitter, chain)}</div>
                        </div>
                    </div>
                    {this.getChainBody(chain)}
                    <p className="chainNav">
                        {chain.ssStakingPage != null && <a className="animLink chainLink linkBorder" href={chain.ssStakingPage}>Delegate</a>}
                        {chain.ssRestakePage != null && <a className="animLink chainLink linkBorder" href={chain.ssRestakePage}>Restake</a>}
                        {chain.ssPerformancePage != null && <a className="animLink chainLink linkBorder" href={chain.ssPerformancePage}>Performance</a>}
                        {chain.analyticsWebsite != null && <a className="animLink chainLink linkBorder" href={chain.analyticsWebsite}>Analytics</a>}
                    </p> 
                </div>
            </Col>
        );
    }

    getChainBody(chain) {
        return <div className="chainBody">
            <p className="chainDesc"><q>{chain.description}</q></p>
            {this.getChainStats(chain)}
            {/* <p>Total Staked: {SPUtilities.stakeFormatterRounded(chain.totalStaked)} ${chain.tokenSymbol} {chain.tvl != null && (<span>/ ${SPUtilities.stakeFormatterRounded(chain.tvl)} USD</span>)}</p>
            <p>Commission: {chain.commission}%</p>
            {this.getApr(chain)} */}
        </div>
    }

    getChainStats(chain) {
        return <div className="chainStat">
            <table>
                <tbody>
                    <tr>
                        <td>Staked with Smart Stake: &nbsp;</td>
                        <th>{SPUtilities.stakeFormatterRounded(chain.totalStaked)} ${chain.tokenSymbol}</th>
                        {/* <th>{SPUtilities.stakeFormatterRounded(chain.totalStaked)} ${chain.tokenSymbol} {chain.tvl != null && (<span>/ ${SPUtilities.stakeFormatterRounded(chain.tvl)}</span>)}</th> */}
                    </tr>
                    <tr>
                        <td>Voting Power %: &nbsp;</td>
                        <th>{SPUtilities.percentFormatter(chain.votingPowerPercentage)}</th>
                    </tr>
                    <tr>
                        <td>Commission: &nbsp;</td>
                        <th>{chain.commission}%</th>
                    </tr>
                    {this.getMcap(chain)}
                    {this.getApr(chain)}
                    {this.getInactive(chain)}
                    {this.getRelayInd(chain)}
                </tbody>
            </table>
        </div>
    }

    getRelayInd(chain) {
        if (chain.relayInd === "true") {
            return (<tr><td>IBC Relayer: &nbsp;</td>
                    <th><a href="https://relayers.smartstake.io/relayer/DD06F013A474ACA3" className="animLinkVisual">Running</a></th>
                </tr>);
        }

        return "";
    }

    getApr(chain) {
        if (chain.currentRewardRate != null && chain.currentRewardRate != 0) {
            // if (chain.app != "LUNC") {
            return (<tr><td>Current Expected Returns: &nbsp;</td>
                    <th>{SPUtilities.stakeFormatterRoundedTwo(chain.currentRewardRate)}%</th>
                </tr>);
        }

        return (<tr><td>Current Expected Returns: &nbsp;</td>
            <th>N/A</th>
        </tr>);
    }

    getMcap(chain) {
        if (chain.usdMcap === null && chain.fdv === null) {
            return "";
        }

        let usdMcap = chain.usdMcap != null ? "$" + SPUtilities.stakeFormatterRoundedTwo(chain.usdMcap): "N/A";
        let fdv = chain.fdv != null ? "$" + SPUtilities.stakeFormatterRoundedTwo(chain.fdv): "N/A";
        return (<tr>
                <td>Market Cap/FDV: &nbsp;</td>
                <th>{usdMcap} / {fdv}</th>
            </tr>);
    }

    static chainLogo(logo) {
      let size = LogoUtils.getImageSize(true);
  
      let imagePath = LogoUtils.getProjectLogoPath(logo);
      return (<img className="logoStyle" width={size} height={size} src={imagePath}/>);
    }
  
    getInactive(chain) {
        let validStatuses = ["Active", "Bonded", "Elected", "Not_Elected"];
        if (!validStatuses.includes(chain.status)) {
            return (<tr><td>Status: &nbsp;</td>
                <th className="inactive">Inactive</th>
            </tr>);
        }

        return "";
    }
}

export default Chain;
